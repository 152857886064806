import React from 'react'
import ReactMarkdown from 'react-markdown'
import { getStrapiMedia } from '../utils'
import Image from './Image'

const renderers = {
  img: ({ alt, src }: { alt: string; src: string }) => (
    <Image
      className="helpPage__postImage"
      image={{
        data: {
          attributes: {
            url: src,
            alternativeText: alt,
            width: 300,
            height: 300,
            formats: {},
          },
        },
      }}
      objectFit="contain"
    ></Image>
  ),
}

const Markdown = ({
  body,
  className,
}: {
  body: string
  className?: string
}) => (
  <ReactMarkdown
    className={`markdown ${className}`}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transformImageUri={(src) => getStrapiMedia(src)}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    components={renderers}
  >
    {body}
  </ReactMarkdown>
)

export default Markdown
