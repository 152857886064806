import { StrapiGlobal } from '../../types/StrapiGlobal'
import StrapiImage from '../../types/StrapiImage'
import Image from '../Image'
import Markdown from '../Markdown'

interface SplitSectionProps {
  title: string
  body: string
  subtitle: string
  reverse: boolean
  image: StrapiImage
  global: StrapiGlobal
}

const SplitSection = ({
  title,
  body,
  subtitle,
  image,
  reverse,
}: SplitSectionProps) => (
  <section className="blockSplitSection">
    <div
      className={`blockSplitSection__columns ${
        reverse && 'blockSplitSection__columns___reverse'
      }`}
    >
      <div className="blockSplitSection__column">
        <Image
          image={image}
          className="blockSplitSection__image"
          format="medium"
        ></Image>
      </div>
      <div className="blockSplitSection__column">
        <div className="blockSplitSection__textColumn">
          <p className="blockSplitSection__subtitle">{subtitle}</p>
          <h3 className="blockSplitSection__title">{title}</h3>
          <Markdown body={body} className="blockSplitSection__body"></Markdown>
        </div>
      </div>
    </div>
  </section>
)

export default SplitSection
